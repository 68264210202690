<template>
  <div :class="$style.root">
    <code
      v-if="!isDevelopment && error"
      v-html="error"
    />
    <div v-else-if="!isDevelopment">
      {{ $t('error.public.message') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    error: {
      type: Object,
      required: true,
    }
  })

  // note extract
  const runtimeConfig = useRuntimeConfig()
  const env = runtimeConfig.env || runtimeConfig.public.env
  const isDevelopment = env?.toLowerCase() === 'development'

  if (!isDevelopment) {
    useBugsnag().notify('Error message', props.error)
  }
</script>

<style module>
.root {
  padding: var(--unit--default);
}
</style>
